import { EmployeeEvaluationDetails } from "../domain/EmployeeEvaluationDetails";
import { EmployeeEvaluationDetailsDTO } from "../dtos/EmployeeEvaluationDetailsDTO";

export function APIEmployeeEvaluationDetailsToDomainMapper(apiDto: EmployeeEvaluationDetailsDTO): EmployeeEvaluationDetails {
    return {
        evaluationPeriodStartDate: apiDto.evaluationPeriodStartDate,
        evaluationPeriodEndDate: apiDto.evaluationPeriodEndDate,
        evaluationPeriodName: apiDto.evaluationPeriodName,
        evaluationPeriodId: apiDto.evaluationPeriodId,
        state: apiDto.state,
        id: apiDto.id,
        targetEmployee: {
            email: apiDto.targetEmployee.userEmail,
            id: apiDto.targetEmployee?.userId ?? "",
            name: apiDto.targetEmployee.userDisplayName,
            employeeID: apiDto.targetEmployee?.userEmployeeID ?? "",
            
        },
        employeeFactorTotal: apiDto.employeeFactorTotal,
        employeeScoreTotalPercentage: apiDto.employeeScoreTotalPercentage ?? 0,
        finalScorePercentage: apiDto.finalScorePercentage,
        finalScoreWithCutPercentage: apiDto.finalScoreWithCutPercentage,
        companyFactorTotal: apiDto.companyFactorTotal,
        feedback: apiDto.feedback,
        evaluationValues: apiDto.evaluationValues,

    }
}


