import "./PersonalEvaluationPage.css";

import { InternationalizationService, translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useServiceCallPro, useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";

import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { CompanyCriteria } from "../../../matrixes/models/domain/CompanyCriteria";
import { DateTime } from "luxon";
import { EmployeeCriteria } from "../../../matrixes/models/domain/EmployeeCriteria";
import { EmployeeEvaluationDetails } from "../../models/domain/EmployeeEvaluationDetails";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationMatrix } from "../../../matrixes/models/domain/EvaluationMatrix";
import { EvaluationPeriodMatrixesService } from "../../../matrixes/services/EvaluationPeriodMatrixesService";
import { EvaluationPeriodStateTranslationTerms } from "../../../../models/enums/EvaluationPeriodState";
import { EvaluationPeriodSummary } from "../../../periods/models/domain/EvaluationPeriodSummary";
import { EvaluationsService } from "../../../evaluations/services/EvaluationsService";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { GetEvaluationPeriodStateCss } from "../../../../common/helpers/GetEvaluationPeriodStateCss";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { StatusCard } from "../../../../lib/components/cards/stats-card/StatusCard";
import { Tag } from "../../../../lib/components/tag/Tag";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";

function FinalScoreFromPercentage(percentage: number | undefined): number | undefined {
    if (percentage === undefined) { return undefined; }
    if (percentage < 26) {
        return 1;
    } else if (percentage < 51) {
        return 2;
    } else if (percentage < 71) {
        return 3;
    } else if (percentage < 90) {
        return 4;
    }

    return 5;
}

function FinalEvaluationPerformance(percentage: number | undefined): string {
    if (percentage === undefined) { return ""; }
    if (percentage < 26) {
        return translate("EVALUATIONS.PERFORMANCE.ToImprove");
    } else if (percentage < 51) {
        return translate("EVALUATIONS.PERFORMANCE.Reasonable");
    } else if (percentage < 71) {
        return translate("EVALUATIONS.PERFORMANCE.Good");
    } else if (percentage < 90) {
        return translate("EVALUATIONS.PERFORMANCE.VeryGood");
    }
    return translate("EVALUATIONS.PERFORMANCE.Exceptional");
}

var evaluationSvc = new EvaluationsService();
var svc = new EvaluationPeriodMatrixesService();


interface IPersonalEvaluationPageProps { }

interface EmployeeEvaluationValue {
    factorName: string;
    evaluationValue: number | undefined;
}

export function PersonalEvaluationPage(props: IPersonalEvaluationPageProps) {
    const openPopup = usePopup();
    const locationID = useGlobalLocation();
    const { id: evaluationID } = useParams();

    const [employeeEvaluation, setEmployeeEvaluation] = useState<EmployeeEvaluationDetails>();

    const [matrix, setMatrix] = useState<EvaluationMatrix | null>();
    const [employeeEvaluationValues, setEmployeeEvaluationValues] = useState<EmployeeEvaluationValue[]>([]);


    /*****************
     * SERVICE CALLS 
    ****************/


    const [getMyEvaluationDetailsCall, getMyEvaluationDetailsCallIsLoading] = useServiceCallPro2(evaluationSvc, evaluationSvc.getMyEvaluationDetails);
    const [getEvaluationPeriodMatrixCall, getEvaluationPeriodMatrixCallIsLoading] = useServiceCallPro2(svc, svc.getEvaluationPeriodMatrix);



    /****************************
    * DATA REQUESTS
    *****************************/


    const getMyEvaluationDetails = useCallback(() => {

        if (!locationID || !evaluationID) return;


        getMyEvaluationDetailsCall(`${locationID}`, evaluationID)
            .then((data) => {
                if (data)
                    setEmployeeEvaluation(data);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, evaluationID, getMyEvaluationDetailsCall, setEmployeeEvaluation, openPopup]);




    const getEvaluationPeriodMatrix = useCallback(() => {
        if (!locationID || !employeeEvaluation?.evaluationPeriodId) return;

        getEvaluationPeriodMatrixCall(`${locationID}`, `${employeeEvaluation.evaluationPeriodId}`)
            .then((data) => {
                setMatrix(data);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, employeeEvaluation?.evaluationPeriodId, setMatrix, getEvaluationPeriodMatrixCall, openPopup]);







    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/


    useEffect(() => {
        getMyEvaluationDetails();
    }, []);



    useEffect(() => {
        getEvaluationPeriodMatrix();
    }, [locationID, employeeEvaluation]);



    useEffect(() => {
        if (!employeeEvaluation?.evaluationValues || !matrix?.employeeCriterias) return;

        var employeeCriteriasInMatrix = matrix.employeeCriterias.map(ec => {
            const foundEvaluation = employeeEvaluation.evaluationValues.find(ev => ev.targetEmployeeCriteriaId === ec.id);

            return {
                evaluationValue: foundEvaluation?.value ?? undefined,
                factorName: ec.name

            } as EmployeeEvaluationValue;
        });

        setEmployeeEvaluationValues(employeeCriteriasInMatrix);

    }, [employeeEvaluation, matrix]);




    const companyCriteriaColumns: ColumnDefinition<CompanyCriteria>[] = useMemo(
        () => [
            {
                columnKey: "criteria",
                cellRenderProp: (v) => v.name,
                headerRender: <>{translate("PERIODS.INFOANDFORM.Factor")}</>,
                isMobilePrimaryCell: true,
            },
            {
                columnKey: "description",
                cellRenderProp: (v) => v.achievementPercentage.toFixed(2),
                headerRender: <>{translate("PERIODS.CRITERIAS.Achievement") + " (%)"}</>,
                width: "20%"

            },
        ], []);



    const employeeCriteriaColumns: ColumnDefinition<EmployeeEvaluationValue>[] = useMemo(
        () => [
            {
                columnKey: "criteria",
                cellRenderProp: (v) => v.factorName,
                headerRender: <>{translate("PERIODS.INFOANDFORM.Factor")}</>,
                isMobilePrimaryCell: true,
            },
            {
                columnKey: "description",
                cellRenderProp: (v) => (<>
                    {FinalScoreFromPercentage(v.evaluationValue)} &nbsp;
                </>),
                headerRender: <>{translate("PERIODS.CRITERIAS.Score")}</>,
                width: "20%"

            },
        ], []);

    return (
        <PageLayout
            tabTitle={translate("EVALUATIONS.Evaluation")}
            showBreadcrumb
            pageTitle={translate("EVALUATIONS.Evaluation")}
            className="personal-evaluation-page"
        >
            {employeeEvaluation ?
                <div className="top-section">
                    <CardContainer className="period-data">
                        <div className="card-header">
                            <div className="subtitle">
                                {translate("PERIODS.INFOANDFORM.EvaluationPeriodData")}
                            </div>
                            <Tag
                                text={translate(EvaluationPeriodStateTranslationTerms[employeeEvaluation.state])}
                                backgroundColor={GetEvaluationPeriodStateCss(employeeEvaluation.state)}
                            />
                        </div>

                        <FormSection unsetFlexGrow>
                            <InfoBlock label={translate("PERIODS.INFOANDFORM.Name")} value={employeeEvaluation.evaluationPeriodName} />
                            <InfoBlock label={translate("PERIODS.INFOANDFORM.InitialDate")} value={employeeEvaluation.evaluationPeriodStartDate ? DateTime.fromISO(employeeEvaluation.evaluationPeriodStartDate).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />
                            <InfoBlock label={translate("PERIODS.INFOANDFORM.FinalDate")} value={employeeEvaluation.evaluationPeriodEndDate ? DateTime.fromISO(employeeEvaluation.evaluationPeriodEndDate).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />
                        </FormSection>
                    </CardContainer>
                    <StatusCard title={translate("EVALUATIONS.Evaluation")} value={"" + FinalScoreFromPercentage(employeeEvaluation.finalScoreWithCutPercentage) ?? "-"} className="evaluation-status-card" />
                </div> : null}

            <div className="criterias-section">
                <CardContainer className="company-criteria">
                    <div className="card-header">
                        <div className="subtitle">
                            {translate("PERIODS.INFOANDFORM.CompanyEvaluationFactors")}
                        </div>
                    </div>
                    <ResponsiveDataTable
                        items={matrix?.companyCriterias || []}
                        columnDefinitions={companyCriteriaColumns}
                        totalitems={matrix?.companyCriterias.length || 0}
                        isLoading={getEvaluationPeriodMatrixCallIsLoading}
                    />
                </CardContainer>
                <CardContainer className="employee-criteria">
                    <div className="card-header">
                        <div className="subtitle">
                            {translate("PERIODS.INFOANDFORM.EmployeeEvaluationFactors")}
                        </div>
                        <Spacer mode="vertical" px={6}/>
                        <div className="employee-performance-text">
                            {employeeEvaluation?.finalScoreWithCutPercentage ? translate("EVALUATIONS.PERFORMANCE.Performance") + ": " + FinalEvaluationPerformance(employeeEvaluation?.employeeScoreTotalPercentage) : null}
                        </div>
                    </div>
                    <ResponsiveDataTable
                        items={employeeEvaluationValues || []}
                        columnDefinitions={employeeCriteriaColumns}
                        totalitems={employeeEvaluationValues.length || 0}
                        isLoading={getEvaluationPeriodMatrixCallIsLoading}
                    />
                </CardContainer>
            </div>
            {employeeEvaluation?.feedback ?
                <CardContainer className="evaluation-observations">
                    <div className="card-header">
                        <div className="subtitle">
                            {translate("EVALUATIONS.INFOANDFORM.Feedback")}
                        </div>
                    </div>
                    <div>{employeeEvaluation?.feedback}</div>
                </CardContainer>
                : null}
        </PageLayout >
    );
}